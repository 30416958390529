var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-banner',{attrs:{"sticky":"","color":"white","single-line":""}},[_c('v-row',{staticClass:"my-2 mx-0"},[_c('v-text-field',{class:{
                    'pl-3 mr-2': !_vm.$vuetify.breakpoint.smAndDown,
                    'pl-3': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"sticky":"","dense":"","filled":"","hide-details":"auto","placeholder":"Search","prepend-inner-icon":"mdi-magnify","rounded":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.findTags.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.findTags.apply(null, arguments)}],"input":_vm.findTags,"change":_vm.findTags},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{class:{
                    'ml-3': _vm.$vuetify.breakpoint.smAndDown,
                    'mt-2': _vm.$vuetify.breakpoint.smAndDown,
                    'mt-0': !_vm.$vuetify.breakpoint.smAndDown,
                    'ml-auto': !_vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"depressed":"","color":"primary","rounded":""},on:{"click":_vm.openDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("New")])],1),_c('ImageForm',{attrs:{"title":"Upload Image","setting":this.setting,"pdfImages":this.pdfImages,"pdfImage":true},on:{"closeDialog":_vm.closeDialog,"addImage":_vm.addImage},model:{value:(_vm.dialogImage),callback:function ($$v) {_vm.dialogImage=$$v},expression:"dialogImage"}})],1)],1),(_vm.pdfImages)?_c('v-row',[_c('viewer',{ref:"viewer",staticClass:"viewer",attrs:{"images":_vm.pdfImages.images,"rebuild":""},on:{"inited":_vm.inited}},_vm._l((_vm.pdfImages.images),function(src){return _c('img',{key:src.imageURL,staticClass:"image",attrs:{"src":src.imageURL,"alt":src.imageURL}})}),0),_vm._l((_vm.pdfImages.images),function(image,index){return _c('v-col',{key:image.id,class:[image.show ? 'd-flex child-flex' : 'd-none'],attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },style:({ position: 'relative' }),attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){return _vm.show(image.id)}}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"aspect-ratio":1,"src":image.imageURL},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),(image.defaultImage)?_c('v-icon',{style:({
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }),attrs:{"color":"yellow"}},[_vm._v("mdi-star")]):_vm._e(),_c('v-card-title',{staticClass:"caption text-capitalize m-0"},[_vm._l((image.tags),function(tag,i){return _c('v-chip',{key:i,staticClass:"mr-1",attrs:{"x-small":"","flat":""}},[_vm._v(_vm._s(tag.toLowerCase()))])}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editTags(image)}}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px","retain-focus":false},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Edit Tags")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex tag-input align-center flex-wrap"},[_vm._l((_vm.imageToUpdate.tags),function(tag,index){return _c('v-chip',{key:index,attrs:{"close":""},on:{"click:close":function($event){return _vm.removeTag(
                                                                        index
                                                                    )}}},[_vm._v(" "+_vm._s(tag)+" ")])}),_c('v-text-field',{attrs:{"dense":"","solo":"","hide-details":"","flat":"","label":"Enter a Tag"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addTag.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.addTag.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.removeLastTag.apply(null, arguments)}]},model:{value:(
                                                                    _vm.tag
                                                                ),callback:function ($$v) {
                                                                    _vm.tag
                                                                =$$v},expression:"\n                                                                    tag\n                                                                "}})],2)])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.updateImage(index)}}},[_vm._v(" Update ")])],1)],1)],1),_c('v-list-item-title',[_vm._v("Edit Tags")])],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.openDelete(image)}}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px","retain-focus":false},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Delete Image")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this image? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.loading},on:{"click":_vm.deleteImage}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-list-item-title',[_vm._v(" Delete ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadFile(image)}}},[_c('v-list-item-title',[_vm._v("Download")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.defaultImage(image.id)}}},[_c('v-list-item-title',[_vm._v("Default Image")])],1)],1)],1)],2)],1)]}}],null,true)})],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }