<template>
    <div>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-banner sticky color="white" single-line>
            <v-row class="my-2 mx-0">
                <v-text-field
                    sticky
                    v-model="search"
                    dense
                    filled
                    @keydown.space="findTags"
                    @input="findTags"
                    @change="findTags"
                    @keydown.enter="findTags"
                    hide-details="auto"
                    placeholder="Search"
                    :class="{
                        'pl-3 mr-2': !$vuetify.breakpoint.smAndDown,
                        'pl-3': $vuetify.breakpoint.smAndDown,
                    }"
                    prepend-inner-icon="mdi-magnify"
                    rounded
                />

                <v-btn
                    :class="{
                        'ml-3': $vuetify.breakpoint.smAndDown,
                        'mt-2': $vuetify.breakpoint.smAndDown,
                        'mt-0': !$vuetify.breakpoint.smAndDown,
                        'ml-auto': !$vuetify.breakpoint.smAndDown,
                    }"
                    @click="openDialog"
                    depressed
                    color="primary"
                    rounded
                >
                    <v-icon left>mdi-plus</v-icon>
                    <span>New</span>
                </v-btn>
                <ImageForm
                    v-model="dialogImage"
                    title="Upload Image"
                    :setting="this.setting"
                    :pdfImages="this.pdfImages"
                    :pdfImage="true"
                    @closeDialog="closeDialog"
                    @addImage="addImage"
                />
            </v-row>
        </v-banner>

        <v-row v-if="pdfImages">
            <viewer
                :images="pdfImages.images"
                @inited="inited"
                class="viewer"
                ref="viewer"
                rebuild
            >
                <img
                    class="image"
                    v-for="src in pdfImages.images"
                    :key="src.imageURL"
                    :src="src.imageURL"
                    :alt="src.imageURL"
                />
            </viewer>
            <v-col
                v-for="(image, index) in pdfImages.images"
                :key="image.id"
                :class="[image.show ? 'd-flex child-flex' : 'd-none']"
                cols="6"
            >
                <v-hover v-slot="{ hover }">
                    <v-card
                        @click="show(image.id)"
                        :elevation="hover ? 5 : 0"
                        :class="{ 'on-hover': hover }"
                        :style="{ position: 'relative' }"
                    >
                        <v-img
                            :aspect-ratio="1"
                            :src="image.imageURL"
                            class="grey lighten-2"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>

                        <v-icon
                            v-if="image.defaultImage"
                            color="yellow"
                            :style="{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }"
                            >mdi-star</v-icon
                        >

                        <v-card-title class="caption text-capitalize m-0">
                            <v-chip
                                class="mr-1"
                                x-small
                                flat
                                v-for="(tag, i) in image.tags"
                                :key="i"
                                >{{ tag.toLowerCase() }}</v-chip
                            >
                            <v-spacer></v-spacer>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click.stop="editTags(image)">
                                        <v-dialog
                                            v-model="editDialog"
                                            persistent
                                            max-width="400px"
                                            :retain-focus="false"
                                        >
                                            <v-card :loading="loading">
                                                <v-card-title class="text-h5">
                                                    Edit Tags</v-card-title
                                                >
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <div
                                                                class="d-flex tag-input align-center flex-wrap"
                                                            >
                                                                <v-chip
                                                                    @click:close="
                                                                        removeTag(
                                                                            index
                                                                        )
                                                                    "
                                                                    v-for="(tag,
                                                                    index) in imageToUpdate.tags"
                                                                    :key="index"
                                                                    close
                                                                    class=""
                                                                >
                                                                    {{ tag }}
                                                                </v-chip>

                                                                <v-text-field
                                                                    @keydown.enter="
                                                                        addTag
                                                                    "
                                                                    @keydown.space="
                                                                        addTag
                                                                    "
                                                                    @keydown.delete="
                                                                        removeLastTag
                                                                    "
                                                                    dense
                                                                    solo
                                                                    hide-details
                                                                    v-model="
                                                                        tag
                                                                    "
                                                                    flat
                                                                    label="Enter a Tag"
                                                                >
                                                                </v-text-field>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn
                                                        text
                                                        color="secondary"
                                                        @click="
                                                            editDialog = false
                                                        "
                                                    >
                                                        Close
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        :loading="loading"
                                                        @click="
                                                            updateImage(index)
                                                        "
                                                    >
                                                        Update
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-list-item-title
                                            >Edit Tags</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item
                                        @click.stop="openDelete(image)"
                                    >
                                        <v-dialog
                                            v-model="deleteDialog"
                                            persistent
                                            max-width="400px"
                                            :retain-focus="false"
                                        >
                                            <v-card :loading="loading">
                                                <v-card-title class="text-h5"
                                                    >Delete Image</v-card-title
                                                >
                                                <v-card-text>
                                                    Are you sure you want to
                                                    delete this image?
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn
                                                        text
                                                        color="secondary"
                                                        @click="
                                                            deleteDialog = false
                                                        "
                                                    >
                                                        Close
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        @click="deleteImage"
                                                        text
                                                        color="error"
                                                        :loading="loading"
                                                    >
                                                        Delete
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-list-item-title>
                                            Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="downloadFile(image)">
                                        <v-list-item-title
                                            >Download</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item
                                        @click="defaultImage(image.id)"
                                    >
                                        <v-list-item-title
                                            >Default Image</v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-card-title>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { storage } from '@/services/firebase'
import ImageForm from '@/components/Settings/ImageForm'
import API from '@/services/api'
import _ from 'lodash'
export default {
    name: 'SettingsImage',
    components: {
        ImageForm,
    },
    props: {
        setting: Object,
        commercialTerms: Array,
    },
    data() {
        return {
            folder: 'pdf_images',
            dialogImage: false,
            company: JSON.parse(localStorage.getItem('company')),
            item: { title: 'Prueba' },
            transparent: 'rgba(255, 255, 255, 0)',
            loading: false,
            search: '',
            deleteError: false,
            error: false,
            errorMsg: null,
            imageToDelete: null,
            deleteDialog: false,
            imageToUpdate: {},
            editDialog: false,
            tag: '',
        }
    },
    computed: {
        ...mapState(['pdfImages']),
    },
    watch: {
        pdfImages: function(newPdf) {
            if (newPdf) {
                this.setShow()
            }
        },
    },
    async created() {
        try {
            this.loading = true
            this.error = false
            this.errorMsg = ''
            await this.getPdfImages()
            if (this.pdfImages) {
                this.setShow()
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapActions(['addImagesAction']),
        ...mapMutations(['setErrorItems']),
        getPdfImages: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                const {
                    data: { pdfImages },
                } = await API.getPdfImages(id)
                //validate if images exist
                if (pdfImages[0].images) {
                    for (let image of pdfImages[0].images) {
                        if (image.file) {
                            const path = `${this.company}/${this.folder}`
                            const storageRef = storage().ref(path)
                            const profilePicRef = storageRef.child(image.file)
                            image.imageURL = await profilePicRef.getDownloadURL()
                        }
                    }
                }
                this.addImagesAction(pdfImages[0])
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openDelete(image) {
            this.deleteDialog = true
            this.imageToDelete = image
        },
        addImage(file) {
            this.pdfImages.images.push(file)
        },
        inited(viewer) {
            this.$viewer = viewer
        },
        setShow() {
            if (this.pdfImages.images) {
                this.pdfImages.images.forEach(image => {
                    image.show = true
                })
            }
        },
        findTags() {
            if (this.search) {
                const regex = new RegExp(this.search.toLowerCase(), 'gi')
                this.pdfImages.images.forEach(image => {
                    const images = image.tags.filter(tag => tag.match(regex))

                    if (images.length > 0) {
                        image.show = true
                    } else {
                        image.show = false
                    }
                })
            } else {
                this.pdfImages.images.forEach(image => (image.show = true))
            }
        },
        downloadFile(image) {
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = () => {
                var blob = xhr.response
                var fileUrl = URL.createObjectURL(blob)
                var fileLink = document.createElement('a')
                fileLink.href = fileUrl
                fileLink.setAttribute('download', image.file)
                document.body.appendChild(fileLink)
                fileLink.click()
            }
            xhr.open('GET', image.imageURL)
            xhr.send()
        },
        show(id) {
            const index = this.pdfImages.images.findIndex(x => x.id === id)
            this.$viewer.index = index
            this.$viewer.show()
        },
        deleteOnStorage: async function(file) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(file)
            await refDoc.delete()
            return true
        },
        deleteImage() {
            this.upload()
        },
        openDialog() {
            this.dialogImage = true
        },
        closeDialog() {
            this.dialogImage = false
        },
        upload: async function() {
            try {
                const index = this.pdfImages.images.findIndex(
                    x => x.id == this.imageToDelete.id
                )
                const { id } = this.setting
                await API.updateImages(id, this.pdfImages)
                this.pdfImages.images.splice(index, 1)
                this.loading = true
                this.error = false
                this.errorMsg = null
                const pdfImages = _.cloneDeep(this.pdfImages.images)
                pdfImages.forEach(i => {
                    delete i.imageURL
                })
                await this.deleteOnStorage(this.imageToDelete.file)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        defaultImage: async function(imageId) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const imageNewIndex = this.pdfImages.images.findIndex(
                    image => image.id === imageId
                )
                const imageOldIndex = this.pdfImages.images.findIndex(
                    image => image.defaultImage === true
                )
                if (imageOldIndex >= 0) {
                    this.pdfImages.images[imageOldIndex].defaultImage = false
                }
                this.pdfImages.images[imageNewIndex].defaultImage = true
                const { id } = this.setting
                await API.updateImages(id, this.pdfImages)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        editTags: function(image) {
            this.imageToUpdate = image
            this.editDialog = true
        },
        updateImage: async function(index) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                const { id } = this.setting
                await API.updateImages(id, this.pdfImages)
                this.pdfImages.images.splice(index, 1, this.imageToUpdate)
                this.editDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addTag(event) {
            event.preventDefault()
            var val = event.target.value.trim().toLowerCase()
            if (val.length > 0) {
                this.imageToUpdate.tags.push(val)
                event.target.value = ''
                this.tag = ''
            }
        },
        removeTag(index) {
            this.imageToUpdate.tags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
                this.removeTag(this.imageToUpdate.tags.length - 1)
            }
        },
    },
}
</script>
<style scoped>
.v-card {
    transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.9;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__wrapper {
    padding-left: 16px;
}
.show-btns {
    color: #0277bd !important;
}
.image {
    display: none;
}
</style>
